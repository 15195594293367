<template>
    <div id="CapexPlanning">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGrid.vue'

export default {
    name:'CapexPlanning',

    components:{
        ZnapHotGrid
    },

    data() {
        return {
            options: [
                {
                    column: 'id_event',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Evento *',
                    required: true,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipEvent, 'event' ]
                },
                {
                    column: 'id_purpose',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Propósito *',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ]
                },
                {
                    column: 'id_cost_center',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Centro de custo *',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipOrganization, 'purpose-cost-center' ]
                },
                {
                    column: 'id_sales_channel',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Tipo de contrato',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-channel', 'list-options-planning' ],
                    conditions: [
                    {
                        AndOr: "AND",
                        column: "id_sales_channel_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_planning_group_version",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "id_event_type",
                        operator: "=",
                        value: null
                    },
                    {
                        AndOr: "AND",
                        column: "fiscal_year",
                        operator: "=",
                        value: null
                    }
                ]
                },
                {
                    column: 'id_sales_force',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Gerente executivo',
                    required: true,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                    conditions: [
                        {
                            AndOr: "AND",   
                            column: "id_sales_force_type",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "id_planning_group_version",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "id_event_type",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "fiscal_year",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "id_sales_channel",
                            operator: "=",
                            value: null, // pegar dinâmico
                        },
                    ]
                },
                {
                    column: 'id_sales_force_bp',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'BP',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipSales, 'sales-force', 'list-options-planning' ],
                    conditions: [
                        {
                            AndOr: "AND",   
                            column: "id_sales_force_type",
                            operator: "=",
                            value: 7
                        },
                        {
                            AndOr: "AND",
                            column: "id_planning_group_version",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "id_event_type",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "fiscal_year",
                            operator: "=",
                            value: null
                        }
                    ]
                },
                {
                    column: 'id_customer_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta cliente',
                    required: false,
                    filterable: true,
                    endpoint: [ Vue.prototype.$ipCustomer, 'customer-group', 'list-options-planning' ],
                    conditions: [
                        {
                            AndOr: "AND",   
                            column: "id_customer_group_type",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "id_planning_group_version",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "id_event_type",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "fiscal_year",
                            operator: "=",
                            value: null
                        },
                        {
                            AndOr: "AND",
                            column: "id_sales_channel",
                            operator: "=",
                            value: null, // pegar dinâmico
                        },
                        {
                            AndOr: "AND",
                            column: "id_sales_force",
                            operator: "=",
                            value: null, // pegar dinâmico
                        },
                    ]
                },
                {
                    column: 'id_asset_class',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Classe do ativo',
                    required: false,
                    filterable: false,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipCapexPlanning, 'asset-class' ]
                },
                {
                    column: 'id_account_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta gerencial',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipOrganization, 'purpose-account' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_purpose',
                            operator: 'IN',
                            value: null
                        }
                    ]
                },
                {
                    column: 'id_chart_account',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Conta contábil',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipAccount, 'chart-account-group' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_account_group',
                            operator: 'IN',
                            value: null
                        },
                        {
                            AndOr: 'AND',
                            column: 'id_chart_account_group_version',
                            operator: '=',
                            value: null
                        }
                    ]
                },
                {
                    column: 'id_asset_project',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Projeto',
                    required: false,
                    filterable: false,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipCapexPlanning, 'asset-project' ]
                },
                {
                    column: 'id_asset',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Ativo',
                    required: false,
                    filterable: false,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipCapexPlanning, 'asset' ]
                },
                {
                    column: 'id_status',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Status',
                    required: false,
                    filterable: true,
                    isDropdownOption: true,
                    endpoint: [ Vue.prototype.$ipUnit, 'status' ],
                    conditions: [
                        {
                            AndOr: 'AND',
                            column: 'id_status_type',
                            operator: '=',
                            value: 8
                        },
                    ]
                },
            ]
        }
    }
}
</script>

<style scoped>
</style>